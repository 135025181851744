<template>
    <div class="right-chart-1" >


        <div class="rc1-chart-container" style="cursor: pointer" >
            <div class="left">
                <div class="number">{{ reduceTotal() }}</div>
                <div>换标总数</div>
            </div>

            <dv-capsule-chart class="right" :config="config"/>
        </div>
    </div>
</template>

<script>
import {createModel} from "@/views/data-report/create-model";
import shopBox from "@/views/data-report/shopBox";

export default {
	name: 'RightChart1',
	props: {
		total: {
			type: Number,
			default: 0
		},
		config: {
			type: Object,
			default() {
				return {}
			}
		}
	},
	data() {
		return {}
	},
	methods: {
		reduceTotal() {
			let sum = 0
			const {data} = this.config
			if (data) {
				data.forEach(item => {
					sum += item.value
				})
			}
			return sum
		},
		open() {
			createModel(shopBox)
		}
	}
}
</script>

<style lang="less">
.right-chart-1 {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;


  .rc1-header {
    font-size: 24px;
    font-weight: bold;
    height: 30px;
    line-height: 30px;
  }

  .rc1-chart-container {
    flex: 1;
    display: flex;
  }

  .left {
    width: 30%;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .number {
      font-size: 34px;
      color: #096dd9;
      font-weight: bold;
      margin-bottom: 30px;
    }
  }

  .right {
    flex: 1;
    padding-bottom: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
}
</style>
