<template>
    <div style="width: 100%;height: 100%">
        <Echart width="100%" height="100%" :options="options"></Echart>
    </div>
</template>

<script>
import Echart from "@/common/echart";

export default {
	name: "leftCenterB1",
	components: {
		Echart
	},
	data() {
		return {
			options: {
				tooltip : {
					trigger: 'axis'
				},
				grid: {
					x: 46,
					y:30,
					x2:30,
					y2:40,
					borderWidth: 0
				},
				legend: {
					data:['入库','出库'],
					textStyle:{
						color:"#09ebee"

					}
				},

				calculable : false,
				xAxis : [
					{
						type : 'category',
						data : ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
						axisLabel: {
							show: true,
							textStyle: {
								color: '#04a7ab',
								align: 'center'
							},
							splitLine: {
								show: false
							},
						}
					}

				],
				yAxis : [
					{
						type : 'value',
						axisLabel: {
							show: true,
							textStyle: {
								color: '#04a7ab',
								align: 'right'
							}
						},
						splitLine: {
							show: false
						},
					}
				],
				series : [
					{
						name:'入库',
						type:'bar',
						data:[1238,1080, 1536, 1250, 2020, 1256, 1200, 1762, 2505, 1163, 2569, 3036],
						markPoint : {
							data : [
								{type : 'max', name: '最大值'},
								{type : 'min', name: '最小值'}
							]
						},
						markLine : {
							data : [
								{type : 'average', name: '平均值'}
							]
						}
					},
					{
						name:'出库',
						type:'bar',
						data:[983, 820, 1236, 930, 1600, 1032, 890, 1300, 1921, 984, 1960, 2430],
						markPoint : {
							data : [
								{name : '月最高', value : 2630, xAxis: 12, yAxis: 2630, symbolSize:18},
								{name : '月最低', value : 820, xAxis: 2, yAxis: 830}
							]
						},
						markLine : {
							data : [
								{type : 'average', name : '平均值'}
							]
						}
					}
				]
			}
		}
	}
}
</script>

<style scoped>

</style>
