<template>
    <dv-charts :option="option"/>
</template>

<script>
import {getLastDays} from "@/utils";

export default {
	name: "dataCenterTop",
	data() {
		return {
			option: {
				legend: {
					data: ['一件代发服务量'],
					textStyle: {
						fill: '#fff'
					}
				},
				xAxis: {
					data: getLastDays(16),
					boundaryGap: false,
					axisLine: {
						style: {
							stroke: '#99f9ff'
						}
					},
					axisLabel: {
						style: {
							fill: '#99f9ff'
						}
					},
					axisTick: {
						show: false
					}
				},
				yAxis: {
					data: 'value',
					splitLine: {
						show: false
					},
					axisLine: {
						style: {
							stroke: '#99f9ff'
						}
					},
					axisLabel: {
						style: {
							fill: '#99f9ff'
						},
						formatter({value}) {
							return value.toFixed(2)
						}
					},
					axisTick: {
						show: false
					},
					min: 95,
					max: 100,
					interval: 0.5
				},
				series: [
					{
						data: [
							99.56, 99.66, 99.84, 99.22, 99.11, 99.45,
							99.44, 99.81, 99.84, 99.32, 99.14, 99.45,
							99.15, 99.45, 99.64, 99.89
						],
						type: 'line',
						name: '一件代发服务量',
						smooth: true,
						lineArea: {
							show: true,
							gradient: ['rgba(55, 162, 218, 0.6)', 'rgba(55, 162, 218, 0)']
						},
						linePoint: {
							radius: 4,
							style: {
								fill: '#00db95'
							}
						}
					}
				]
			}
		}
	}
}
</script>

<style scoped>

</style>
