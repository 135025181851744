<template>
    <div class="box">
        <div class="left" :style="{paddingTop:box_height}">
            <div style="flex: 1">
                <Box title="FBA中转">
                    <DataRightTop></DataRightTop>
                </Box>

            </div>
            <div style="flex: 1">
                <Box title="退货入仓趋势">
                    <DataleftCenter></DataleftCenter>
                </Box>
            </div>
            <div style="flex: 1">
                <Box title="主要服务类比">
                    <DataLeftBottom></DataLeftBottom>
                </Box>
            </div>
        </div>
        <div class="center">

            <div style="flex: 1">
                <Box title="一件代发总动态">
                    <DataCenterTop></DataCenterTop>
                </Box>
            </div>
            <div style="flex: 1">
                <Box title="主要服务类比分布">
                    <DatacenterCenter></DatacenterCenter>
                </Box>
            </div>
            <div style="flex: 1">
                <Box title="入库统计">
                    <DatacenterBottom></DatacenterBottom>
                </Box>
            </div>

        </div>
        <div class="right">
            <div style="flex: 1">
                <Box title="退货换标" >
                    <RightChart1 :config="config"></RightChart1>
                </Box>


            </div>
            <div style="flex: 1">
                <Box title="滚动实时订单">
                    <RightScrollBoard></RightScrollBoard>
                </Box>
            </div>
            <div style="flex: 1;">

                <Box title="检测服务">
                    <LeftCenter></LeftCenter>
                </Box>
            </div>
        </div>
    </div>
</template>

<script>
import Box from "_c/box";
import LeftCenter from "@/views/data-report/chart/leftCenter";
import DataleftCenter from "@/views/data-report/chart/dataleftCenter";
import DataLeftBottom from "@/views/data-report/chart/dataLeftBottom";
import DatacenterBottom from "@/views/data-report/chart/datacenterBottom";
import DatacenterCenter from "@/views/data-report/chart/datacenterCenter";
import DataRightTop from "@/views/data-report/chart/dataRightTop";
import DataCenterTop from "@/views/data-report/chart/dataCenterTop";
import RightScrollBoard from "@/views/data-report/chart/RightScrollBoard";

import RightChart1 from "@/views/data-report/chart/RightChart2";

export default {
	name: "dataA",
	components: {
		Box,
		RightChart1,

		LeftCenter,
		RightScrollBoard,
		DataleftCenter,
		DataLeftBottom,
		DataRightTop,
		DatacenterCenter,
		DatacenterBottom,
		DataCenterTop
	},
	props: {
		box_height: {
			type: String,
			default() {
				return '110px';
			}
		}
	},
    data(){
		return {
			config:{
				data: [
					{
						name: '纺织品类',
						value: 216589
					},
					{
						name: '汽配用品',
						value: 191562
					},
					{
						name: '厨房用品',
						value: 36551
					},
					{
						name: '运动户外',
						value: 86212
					},


				],
				unit: '件'
            }
        }
    }
}
</script>

<style scoped lang="less">
.box {
  padding: 8px 0;
  & > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0;

    &.center {
      flex: 2;

    }

    &.right {
      flex: 2;
    }
  }

}
</style>
