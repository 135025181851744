<template>
    <div style="width: 100%;height: 100%">
        <Echart width="100%" height="100%" :options="options"></Echart>
    </div>
</template>

<script>
import Echart from "@/common/echart";

export default {
	name: "leftCenterD",
	components: {
		Echart
	},
	data() {
		return {
			options: {
				tooltip: {   //提示框，鼠标悬浮交互时的信息提示
					show: true,
					trigger: 'axis'
				},
				grid: {
					x: 46,
					y:30,
					x2:30,
					y2:40,
					borderWidth: 0
				},
				legend: {
					data: [],
					orient: 'vertical',
					textStyle: { fontWeight: 'bold', color: '#00f7fb' }
				},

				calculable: false,
				xAxis: [
					{
						type: 'category',
						boundaryGap: false,
						data : ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
						splitLine: {
							show: false
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#04f7fb',
								align: 'center'
							}
						}
					}

				],
				yAxis: [
					{
						type: 'value',
						splitLine: {
							show: false
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#04f7fb',
								align: 'right'
							}
						}
					}
				],
				series: [
					{
						name: '',
						type: 'line',
						stack: 'xx数',
						data:[983, 820, 1236, 930, 1600, 1032, 890, 1300, 1421, 984, 1960, 1630],
						itemStyle: {
							normal: {
								color: '#02acbc'
							}
						}
					}
				]
			}
		}
	}
}
</script>

<style scoped>

</style>
