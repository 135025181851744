<template>
    <div style="width: 100%;height: 100%">
        <Echart width="100%" height="100%" :options="options"></Echart>
    </div>
</template>

<script>
import Echart from "@/common/echart";

export default {
	name: "leftCenter",
	components: {
		Echart
	},
	data() {
		return {
			options: {
				tooltip: {
					trigger: 'axis'
				},
				grid: {
					x: 46,
					y: 30,
					x2: 30,
					y2: 20,
					borderWidth: 0
				},

				calculable: false,
				legend: {
					data: ['检测翻新', '回国维修', '检测弃仓', '检测总量'],
					textStyle: {
						color: "#f9fbfe"

					}
				},
				xAxis: [
					{
						type: 'category',
						data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
						splitLine: {
							show: false
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#00f7fb',
								align: 'center'
							}
						}

					}
				],
				yAxis: [
					{
						type: 'value',

						name: '数量',
						axisLabel: {
							formatter: '{value} ',
							textStyle: {
								color: '#00f7fb',
								align: 'right'
							}
						},
						splitLine: {
							show: false
						},
					},
				],
				series: [

					{
						name: '检测翻新',
						type: 'bar',
						data: [122.0, 114.9, 117.0, 123.2, 125.6, 176.7, 135.6, 162.2, 132.6, 120.0, 116.4, 113.3],
						itemStyle: {
							normal: {
								color: "#0ad5ff"
							}
						}
					},
					{
						name: '回国维修',
						type: 'bar',
						data: [59.6, 55.9, 59.0, 66.4, 58.7, 80.7, 65.6, 82.2, 68.7, 58.8, 56.0, 62.3],
						itemStyle: {
							normal: {
								color: "#005ea1"
							}
						}
					},
					{
						name: '检测弃仓',
						type: 'bar',
						data: [22.6, 15.9, 19.0, 26.4, 18.7, 30.7, 25.6, 32.2, 28.7, 18.8, 16.0, 22.3],
						itemStyle: {
							normal: {
								color: "#2e7cff"
							}
						}
					},

				]
			}
		}
	},
	methods: {
		getData() {
			const s = {
				name: '检测总量',
				type: 'line',

				data: [],
				itemStyle: {
					normal: {
						color: "#1afffd"
					}
				}
			}
			for (let i = 0; i < 12; i++) {
				let n = 0
				this.options.series.forEach(serie => {
					n += serie.data[i]
				})
				s.data.push(n)
			}
			this.options.series.push(s)
		}
	},
	mounted() {
		this.getData()
	}
}
</script>

<style scoped>

</style>
