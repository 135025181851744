<template>
  <div id="scroll-board">
    <dv-scroll-board :config="config" />
  </div>
</template>

<script>
export default {
  name: 'ScrollBoard',
  data () {
    return {
      config: {
        header: ['时间', '收件人', '件数', '订单编号'],
        data: [
          ['2023-12-22 19:25:01', 'A***d', '1', 'Z******1'],
          ['2023-12-22 17:25:20', 'B***a', '3', 'D******2'],
          ['2023-12-22 16:25:03', 'S***l', '6', 'Z******1'],
          ['2023-12-22 15:25:33', 'A***e', '2', 'A******7'],
          ['2023-12-22 14:25:41', 'H***n', '1', 'Z******1'],
          ['2023-12-22 13:25:00', 'J***u', '3', 'A******8'],
          ['2023-12-22 12:25:25', 'A***m', '4', 'Z******2'],
          ['2023-12-22 11:25:56', 'G***r', '2', 'A******6'],
          ['2023-12-22 10:25:12', 'P***a', '1', 'Z******4'],
          ['2023-12-22 09:25:44', 'Q***n', '3', 'C******3']
        ],
        index: true,
        columnWidth: [50, 180,80 ,80],
        align: ['center'],
        rowNum: 7,
        headerBGC: '#1981f611',
        headerHeight: 45,
        oddRowBGC: 'rgba(0, 144, 181, 0.1)',
        evenRowBGC: 'rgba(10, 129, 150, 0.1)'
      }
    }
  }
}
</script>

<style lang="less">
#scroll-board {
  width: 95%;
  box-sizing: border-box;
  margin-left: 20px;
  height: 100%;
  overflow: hidden;
}
</style>
