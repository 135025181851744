<template>
    <div style="width: 100%;height: 100%">
        <Echart width="100%" height="100%" :options="options"></Echart>
    </div>
</template>

<script>
import Echart from "@/common/echart";

export default {
	name: "leftCenterB",
	components: {
		Echart
	},
	data() {
		return {
			options: {
				legend: {
					y : '75%',
					data:['包括一件代发','FBA中转','检测服务','退货换标','其他服务'], textStyle:{
						color:"#e9ebee"

					}
				},
				calculable : false,
				series : [
					{
						name:'因素',
						type:'pie',
						radius : '40%',
						center: ['50%', '35%'],
						data:[
							{value:300, name:'包括一件代发',
								itemStyle: {
									normal: {
										color:"#1afffd"
									}
								}},
							{value:400, name:'FBA中转',
								itemStyle: {
									normal: {
										color:"#2e7cff"
									}
								}},
							{value:100, name:'检测服务',
								itemStyle: {
									normal: {
										color:"#ffcb89"
									}
								}},
							{value:150, name:'退货换标',
								itemStyle: {
									normal: {
										color:"#005ea1"
									}
								}},
							{value:50, name:'其他服务',
								itemStyle: {
									normal: {
										color:"#0ad5ff"
									}
								}}
						]
					}
				]
			}
		}
	}
}
</script>

<style scoped>

</style>
