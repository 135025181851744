<template>
    <div style="width: 100%;height: 100%">
        <Echart width="100%" height="100%" :options="options"></Echart>
    </div>
</template>

<script>
import Echart from "@/common/echart";

export default {
	name: "leftCenterB2",
	components: {
		Echart
	},
	data() {
		return {
			options: {
				tooltip: {
					trigger: 'axis'
				},
				grid: {
					x: 26,
					y: 20,
					x2: 20,
					y2: 20,
					right:'10%',
					borderWidth: 0
				},

				calculable: false,
				legend: {
					data: ['一件代发', 'FBA中转', '检测服务', '退货换标','其他服务'],
					textStyle: {
						color: "#e9ebee"

					}
				},
				xAxis: [
					{
						type: 'category',
						splitLine: {show: false},
						data: [  '澳大利亚','加拿大', '英国', '法国', '德国', '西班牙','美国',],
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff',
								align: 'center'
							}
						}
					}
				],
				yAxis: [
					{
						type: 'value',
						position: 'right',
						splitLine: {show: false},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff',
								align: 'left'
							}
						},

					}
				],
				series: [
					{
						name: '一件代发',
						type: 'bar',
						data: [80, 136, 171, 187, 205, 135, 360],
						itemStyle: {
							normal: {
								color: "#1afffd"
							}
						}
					},
					{
						name: 'FBA中转',
						type: 'bar',
						tooltip: {trigger: 'item'},
						stack: '广告',
						data: [110, 166, 195, 247, 285, 165, 485],
						itemStyle: {
							normal: {
								color: "#2e7cff"
							}
						}
					},
					{
						name: '检测服务',
						type: 'bar',
						tooltip: {trigger: 'item'},
						stack: '广告',
						data: [30, 41, 45, 57, 75, 45,125],
						itemStyle: {
							normal: {
								color: "#ffcb89"
							}
						}
					},
					{
						name: '退货换标',
						type: 'bar',
						tooltip: {trigger: 'item'},
						stack: '广告',
						data: [45, 66, 85, 94, 105, 65, 180],
						itemStyle: {
							normal: {
								color: "#005ea1"
							}
						}
					},
                    {
						name: '其他服务',
						type: 'bar',
						tooltip: {trigger: 'item'},
						stack: '广告',
						data: [15, 22, 31, 34, 40, 20, 60],
						itemStyle: {
							normal: {
								color: "#a05ea1"
							}
						}
					},

					{
						name: '服务细分',
						type: 'pie',
						tooltip: {
							trigger: 'item',
							formatter: '{a} <br/>{b} : {c} ({d}%)'
						},
						center: [120, 78],
						radius: [0, 30],
						itemStyle: {
							normal: {
								labelLine: {
									length: 15
								}
							}
						},
						data: [
							{
								value: 1208, name: '一件代发',
								itemStyle: {
									normal: {
										color: "#1afffd"
									}
								}
							},
							{
								value:1681, name: 'FBA中转',
								itemStyle: {
									normal: {
										color: "#2e7cff"
									}
								}
							},
							{
								value: 447, name: '检测服务',
								itemStyle: {
									normal: {
										color: "#ffcb89"
									}
								}
							},
							{
								value: 702, name: '退货换标',
								itemStyle: {
									normal: {
										color: "#005ea1"
									}
								}
							},
                            {
								value: 202, name: '其他服务',
								itemStyle: {
									normal: {
										color: "#a05ea1"
									}
								}
							}
						]
					}
				]
			}
		}
	}
}
</script>

<style scoped>

</style>
