<template>
    <div style="width: 100%;height: 100%">
        <Echart width="100%" height="100%" :options="options"></Echart>
    </div>
</template>

<script>
import Echart from "@/common/echart";

export default {
	name: "leftCenterB3",
	components: {
		Echart
	},
	data() {
		return {
			options: {
				tooltip : {
					trigger: 'axis'
				},
				grid: {
					x: 46,
					y:30,
					x2:30,
					y2:20,
					borderWidth: 0
				},

				calculable : false,
				legend: {
					data:['换标中转','不换标中转'],
					textStyle:{
						color:"#fff"

					}
				},
				xAxis : [
					{
						type : 'category',
						data : ['周一','周二','周三','周四','周五','周六','周日'],
						splitLine: {
							show: false
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff',
								align: 'center'
							}
						}

					}
				],
				yAxis : [

					{
						type : 'value',
						name : '中转量',
						nameTextStyle : {align:'right'},
						axisLabel : {
							formatter: '{value} ',
							textStyle: {
								color: '#fff',
								align: 'right'
							}
						},
						splitLine: {
							show: false
						},
					},

				],
				series : [

					{
						name:'换标中转',
						type:'bar',
						stack: 'xx量',
						data:[120, 132, 101, 134, 90, 230, 210],
						itemStyle: {
							normal: {
								color:"#ffcb89"
							}
						}
					},
					{
						name:'不换标中转',
						type:'bar',
						stack: 'xx量',
						data:[220, 232, 301, 234, 190, 330, 210],
						itemStyle: {
							normal: {
								color:"#00aea1"
							}
						}
					},


				]
			}
		}
	}
}
</script>

<style scoped>

</style>
